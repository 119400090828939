<template>
	<div class="rc-view press pt-4">
		<v-container class="rc-padding fluid">
			<v-layout wrap row align-center justify-center>
				<v-flex xs12 md12 px-3 class="text-xs-center">
					<h1 class="rc-tituloSection">Prensa</h1>
					<h2 style="font-weight:normal">Mira donde hemos sido publicados</h2>
				</v-flex>
			</v-layout>
			<v-layout wrap row align-end justify-end>
				<v-flex xs12 md3 px3>
					<v-select outline class="border-text" item-id="text" item-value="year" :items="filter" :label="`Filtrar (${press.filter(el => filterValue != null ? el.year == filterValue : true).length})`" v-model="filterValue"></v-select>
				</v-flex>
			</v-layout>
			<v-layout wrap row align-start justify-start>
				<v-flex xs12>
					<v-layout wrap row>
						<v-flex xs12 sm6 md4 lg3 pa-3 v-for="post in press.filter(el => filterValue != null ? el.year == filterValue : true)" :key="post + index">

							<v-card class="rc-shadow elevation-3" style="border-radius: 20px;min-height: 420px !important;padding: 25px 0px;width: 100%;margin: auto;">
								<v-layout wrap align-center justify-center fill-height>
									<v-flex xs12 sm12 md12 text-xs-center style="padding:0px 30px">
										<div :style="{'width': '100%', 'height': '100px', 'background': 'url('+post.image+') no-repeat', 'background-position': 'center','background-size': 'contain'}"></div>
									</v-flex>
								</v-layout>
								<v-card-title style="padding: 1rem 0 0 0;">
									<v-layout wrap align-center justify-center fill-height px-3>
										<v-flex xs12 text-xs-center>
											<p class="mb-0" style="font-size: 18px; color: #818181">
											{{ post.date }}
											</p>
										</v-flex>
									</v-layout>
								</v-card-title>
								<v-card-text>
									<v-layout wrap row align-center justify-center fill-height >
										<v-flex xs12>
											<p class="font-weight-bold align-left" style="text-align: center; font-size: 16px; min-height: 100px">
											{{ post.name }}
											</p>
										</v-flex>
										<div class="hero-card-btn text-xs-center text-md-center">
                      <v-btn color="btn-panel" class="ma-0 mb-1 mt-3 btn-border-radius white--text text-capitalize rc-button_prim" :href="post.url" target="_blank" style="height:50px;width:180px">Ver noticia</v-btn>
										</div>
									</v-layout>
								</v-card-text>
							</v-card>
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
</template>

<script>
	import { Component, Vue } from 'vue-property-decorator'
	import { RedCapital, SelectedCountry, TitleTemplatePublic } from '@/configuration'

@Component({
  metaInfo: {
    title: 'Prensa',
    titleTemplate: TitleTemplatePublic,
    htmlAttrs: {
     //  @ts-ignore
      lang: RedCapital[SelectedCountry].Lang,
    }
  },
  data() {
    return{
      filterValue: null,
      filter: [
        {
          year: null,
          text: 'Todos',
        },
        {
          year: 2020,
          text: '2020',
        },
        {
          year: 2019,
          text: '2019',
        },
        {
          year: 2018,
          text: '2018',
        },
        {
          year: 2017,
          text: '2017',
        },
        {
          year: 2016,
          text: '2016',
        },
      ],
      press: [
        {
          url: 'https://semanaeconomica.com/economia-finanzas/microfinanzas/factoring-acceso-de-garantias-y-prestamos-del-fondo-crecer-tendria-impacto-limitado',
          date: '2020-05-01',
          year: 2020,
          name: 'Acceso de empresas de factoring a garantías y préstamos del Fondo Crecer dará soporte a pymes',
          image: '/images/se-logo.png',
        },
        {
          url: 'https://semanaeconomica.com/economia-finanzas/fondos-de-inversion/factoring-mantiene-su-atractivo-a-pesar-de-paralizacion-de-la-economia',
          date: '2020-04-08',
          year: 2020,
          name: 'Factoring mantendrá su atractivo como inversión a pesar de paralización de la economía',
          image: '/images/se-logo.png',
        },
        {
          url: 'https://semanaeconomica.com/economia-finanzas/microfinanzas/factoring-incentivos-para-dar-conformidad-a-facturas-atraeran-a-mas-inversionistas',
          date: '2020-02-07',
          year: 2020,
          name: 'Factoring: incentivos para dar conformidad a facturas atraerán a más inversionistas',
          image: '/images/se-logo.png',
        },
        {
          url: 'https://www.chvnoticias.cl/bolsillo/redcapital-crowdfunding-pymes-chile_20190529/',
          date: '2019-05-29',
          year: 2019,
          name: 'RedCapital, la plataforma crowdfunding chilena que ayuda a financiar Pymes, se proyecta a toda América',
          image: '/images/chv-logo.png',
        },
        {
          url: 'https://semanaeconomica.com/economia-finanzas/microfinanzas/360008-mercado-de-factoring-digital-peruano-se-consolida-y-ofrece-retorno-atractivo',
          date: '2019-05-20',
          year: 2019,
          name: 'Ley de pago oportuno a 30 días: ¿cuál es el impacto sobre fondos de factoring?',
          image: '/images/se-logo.png',
        },
        {
          url: 'http://portal.nexnews.cl/showN?valor=NTJINzc1NTZBOTI4NzI1NDAzNDU0MTgzNTQxMzY1NDIzMjUyMjc0NTI4Mjg1Mjg3NDUyNzc0MDI1MjI1MzMzNTUyMjc0MFE1NTU1NTU1NTU1NTU1',
          date: '2018-10-29',
          year: 2018,
          name: 'Las nuevas opciones para el financiamiento de las pymes',
          image: '/images/nexnews-logo.png',
        },
        {
          url: 'https://www.df.cl/noticias/brandcorner/red-capital/gustavo-anania-ceo-de-redcapital-cl-la-gente-esta-dispuesta-a-invertir/2018-05-02/092739.html',
          date: '2018-05-02',
          year: 2018,
          name: 'Gustavo Ananía, CEO de RedCapital.cl: "la gente está dispuesta a invertir en las pymes"',
          image: '/images/df-logo.png',
        },
        {
          url: 'https://pulsosocial.com/2018/04/12/celcoin-redcapital-payit-cuota-latina-seedstars-summit-competira-us500-k/',
          date: '2018-04-12',
          year: 2018,
          name: 'Redcapital, Celcoin y Payit, la cuota latina entre las finalistas por el Seedstars Global Winner',
          image: '/images/pulso-logo.png',
        },
        {
          url: 'https://www.df.cl/noticias/empresas/empresas-y-startups/redcapital-cl-unico-emprendimiento-chileno-que-concursa-por-us-1-millon/2018-04-11/163726.html',
          date: '2018-04-11',
          year: 2018,
          name: 'RedCapital.cl el único	emprendimiento Chileno que concursa por $US 1 millón en Suiza',
          image: '/images/df-logo.png',
        },
        {
          url: 'https://www.df.cl/noticias/brandcorner/red-capital/redcapital-cl-potencia-negocio-de-crowdfunding-con-expansion-en-leasing/2017-08-28/164847.html',
          date: '2017-08-28',
          year: 2017,
          name: 'Redcapital.cl potencia negocio de crowdfunfing con expansión en leasing inmobiliario',
          image: '/images/df-logo.png',
        },
        {
          url: 'https://digital.elmercurio.com/2017/08/21/content/pages/img/pdf/TC374R3P.pdf',
          date: '2017-08-21',
          year: 2017,
          name: 'Cambios tecnológicos incentivan a los inversionistas a realizar diversos negocios',
          image: '/images/mercurio-logo.png',
        },
        {
          url: 'http://www.economiaynegocios.cl/noticias/noticias.asp?id=388349',
          date: '2017-08-14',
          year: 2017,
          name: 'Con redcapital.cl cualquier inversionista puede "tener un factoring"',
          image: '/images/mercurio-logo.png',
        },
        {
          url: 'https://www.df.cl/noticias/brandcorner/red-capital/crowdfunding-una-doble-oportunidad-de-credito-e-inversion/2017-08-09/195529.html',
          date: '2017-08-09',
          year: 2017,
          name: 'Crowdfunding: Una Doble oportunidad de crédito e inversión',
          image: '/images/df-logo.png',
        },
        {
          url: 'https://digital.elmercurio.com/2017/08/07/B/VV36QEFM',
          date: '2017-08-07',
          year: 2017,
          name: 'Cuando invertir en pymes se transforma en un buen negocio',
          image: '/images/mercurio-logo.png',
        },
        {
          url: 'http://pyme.emol.com/8648/la-nueva-red-colaborativa-ya-ha-financiado-mas-100-pymes-chile/',
          date: '2017-07-07',
          year: 2017,
          name: '"La red colaborativa que ya ha financiado a más de 100 pymes en Chile"',
          image: '/images/emol-logo.png',
        },
        {
          url: 'http://www.cnnchile.com/noticia/2017/07/03/redcapital-personas-financian-pymes',
          date: '2017-07-03',
          year: 2017,
          name: '"Redcapital: Personas financian Pymes"',
          image: '/images/cnn-logo.png',
        },
        {
          url: 'https://www.df.cl/noticias/empresas/empresas-y-startups/redcapital-planea-llegar-a-peru-y-colombia/2017-07-02/192355.html',
          date: '2017-07-02',
          year: 2017,
          name: '"RedCapital planea llegar a Perú y Colombia"',
          image: '/images/df-logo.png',
        },
        {
          url: 'https://www.forbes.com.mx/red-capital-se-llevo-maximo-reconocimiento-seedstars/amp/',
          date: '2017-06-29',
          year: 2017,
          name: '"Red Capital se llevo el máximo reconocimiento en el Seedstars"',
          image: '/images/forbes-logo.png',
        },
        {
          url: 'http://www.emol.com/noticias/Economia/2017/06/28/864667/RedCapital-se-adjudica-la-edicion-para-Chile-de-Seedstars-World.html',
          date: '2017-06-28',
          year: 2017,
          name: '"RedCapital se adjudica la edición para Chile de Seedstars World"',
          image: '/images/emol-logo.png',
        },
        {
          url: 'http://www.lun.com/Pages/NewsDetail.aspx?dt=2017-06-18&NewsID=375381&BodyID=0&PaginaId=31',
          date: '2017-06-18',
          year: 2017,
          name: '"RedCapital.cl Si no le pagan a tiempo, aquí tiene de dónde sacar plata"',
          image: '/images/lun-logo.png',
        },
        {
          url: 'https://www.capital.cl/negocios-b-la-red-del-millon/',
          date: '2017-05-25',
          year: 2017,
          name: '"RedCapital.cl Negocios B- la red del millón"',
          image: '/images/capital-logo.png',
        },
        {
          url: 'https://issuu.com/revistaaldamir/docs/aldamir_117_-_17abril_web',
          date: '2017-04-17',
          year: 2017,
          name: 'Gustavo Anania - Revista Aldamir - "Con nosotros cualquiera puede invertir"',
          image: '/images/aldamir-logo.png',
        },
        {
          url: 'http://www.fintechlatam.net/2016/12/27/entrevista-diario-financiero-gustavo-anania-ceo-de-redcapital/',
          date: '2016-12-27',
          year: 2016,
          name: 'Entrevista Diario Financiero: Gustavo Anania, CEO De Redcapital',
          image: '/images/df-logo.png',
        },
       //  {
        //   url: 'http://ingenieria.udp.cl/conoce-la-nueva-plataforma-de-crowdfunding-del-egresado-de-ingenieria-civil-industrial-felipe-zanberk/',
        //   date: '2016-05-09',
        //   name: 'Conoce la nueva plataforma de crowdfunding del egresado de Ingeniería Civil Industrial Felipe Zanberk',
        //   image: 'https:www.redcapital.cl/img/udp.png',
        // },
      ],
    }
  },
})
export default class Press extends Vue {}
</script>

<style lang="scss" scoped>
	.line-effect {
		box-shadow: inset 0px -0.5em 0px 0px #fde6c3;
		padding: 0px 25px;
	}
	.btn-go-where-the-endermans-lives {
		display: flex;
		max-height: 36px;
	}
</style>
